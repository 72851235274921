import React from "react";

import Head from "../components/head";
import Shop from "../components/shop/shop";
const Products = () => (
  <>
    <Head dis="Set up as Gujarat's finest IELTS Training Institute with dedicated, Student Centric faculty and adopting 21st Century training techniques imparting both Online/Offline Training." />
    <Shop />
  </>
);

export default Products;
