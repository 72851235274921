import React from "react";
import mainImage from "./main.jpg";
import mainImage2 from "./main2.jpg";
import sk95 from "../products/jacket/sk95/p1.jpg";
import uk117 from "../products/tshirt/uk117/p1.jpg";
import tpk162 from "../products/tshirt/tpk162/p1.jpg";
import tpk194 from "../products/tshirt/tpk194/p1.jpg";
import tck205 from "../products/tshirt/tck205/p1.jpg";
import tck158 from "../products/tshirt/tck158/p1.jpg";
import tck305 from "../products/tshirt/tck305/p1.jpg";
import tck12 from "../products/tshirt/tck12/p1.jpg";
import bw74 from "../products/trackpant/bw74/p1.jpg";
import bk50 from "../products/trackpant/bk50/p1.jpg";
import bk138 from "../products/trackpant/bk138/p1.jpg";
import bk09 from "../products/trackpant/bk09/p1.jpg";
import bk04 from "../products/trackpant/bk04/p1.jpg";
import shw01 from "../products/shorts/shw01/p1.jpg";
import shk07 from "../products/shorts/shk07/p1.jpg";
import shw201 from "../products/shorts/shw201/p1.jpg";
import { Link } from "gatsby";
export default () => {
  return (
    <>
      <div className="shop container">
        <div className="shopMainTwo">
          <Link to="/jackets/uk114">
            <img src={mainImage} alt="" srcset="" />
            <button>OCSO Stylish Jacket UK114</button>
          </Link>
          <Link to="/jackets/uk116">
            <img src={mainImage2} alt="" srcset="" />
            <button>OCSO Stylish Hoody Jacket UK116</button>
          </Link>
        </div>
        <div className="shopSec">
          <Link to="/jackets/sk95">
            <img src={sk95} alt="" srcset="" />
            <button>OCSO Stylish Hoody Jacket SK95</button>
          </Link>
          <Link to="/tshirts/uk117">
            <img src={uk117} alt="" srcset="" />
            <button>OCSO Solid Men's T-shirt UK117</button>
          </Link>
          <Link to="/tshirts/tpk162">
            <img src={tpk162} alt="" srcset="" />
            <button>OCSO Solid Men's T-shirt TPK162</button>
          </Link>
          <Link to="/tshirts/tpk194">
            <img src={tpk194} alt="" srcset="" />
            <button>OCSO Solid Men's T-shirt TPK194</button>
          </Link>
          <Link to="/tshirts/tck205">
            <img src={tck205} alt="" srcset="" />
            <button>OCSO Solid Men's T-shirt TCK205</button>
          </Link>
          <Link to="/tshirts/tck158">
            <img src={tck158} alt="" srcset="" />
            <button>OCSO Solid Men's Black T-shirt TCK158</button>
          </Link>
          <Link to="/tshirts/tck305">
            <img src={tck305} alt="" srcset="" />
            <button>OCSO Solid Men's T-shirt TCK305</button>
          </Link>
          <Link to="/tshirts/tck12">
            <img src={tck12} alt="" srcset="" />
            <button>OCSO Solid Men's T-shirt TCK12</button>
          </Link>
          <Link to="/trackpants/bw74">
            <img src={bw74} alt="" srcset="" />
            <button>OCSO Solid Men's Track Pant BW74</button>
          </Link>
          <Link to="/trackpants/bk50">
            <img src={bk50} alt="" srcset="" />
            <button>OCSO Solid Men's Track Pant BK50</button>
          </Link>
          <Link to="/trackpants/bk138">
            <img src={bk138} alt="" srcset="" />
            <button>OCSO Solid Men's Track Pant Bk138</button>
          </Link>
          <Link to="/trackpants/bk09">
            <img src={bk09} alt="" srcset="" />
            <button>OCSO Solid Men's Joggers BK09</button>
          </Link>
          <Link to="/trackpants/bk04">
            <img src={bk04} alt="" srcset="" />
            <button>OCSO Solid Men's Track Pants BK04</button>
          </Link>
          <Link to="/shorts/shw01">
            <img src={shw01} alt="" srcset="" />
            <button>OCSO Solid Men's shorts SHW01</button>
          </Link>
          <Link to="/shorts/shk07">
            <img src={shk07} alt="" srcset="" />
            <button>OCSO Solid Men's shorts SHK07</button>
          </Link>
          <Link to="/shorts/shw201">
            <img src={shw201} alt="" srcset="" />
            <button>OCSO Solid Men's Black shorts SHW201</button>
          </Link>
        </div>
      </div>
    </>
  );
};
